import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  screenWidth: number = window.screen.width;

  constructor() {  }

}
